<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.refill") }}
      </div>
      <div class="q-gutter-x-md row items-center">
        <q-input
          filled
          v-model="fromDate"
          label="Début"
          mask="date"
          :rules="['date']"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="fromDate">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          filled
          v-model="toDate"
          label="Fin"
          mask="date"
          :rules="['date']"
          class="items-center"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="toDate">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 150px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <download-excel :data="report" :fields="export_fields">
          Export <br />excel
        </download-excel>
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          :display-value="$q.lang.table.columns"
          emit-value
          map-options
          :options="columnsI18n"
          option-value="name"
          options-cover
          style="min-width: 150px"
        />
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="report"
        :columns="columnsI18n"
        row-key="id"
        :pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        class="sticky-header-table"
        wrap-cells="true"
        :visible-columns="visibleColumns"
      >
        <template v-slot:header-cell="props">
          <q-th :props="props">
            {{ props.col.label }}
            <q-tooltip>{{ $t(`tooltip.${props.col.name}`) }}</q-tooltip>
          </q-th>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-sm">
              <q-btn
                icon="visibility"
                square
                round
                dense
                @click="viewDetail(props.key)"
              >
                <q-tooltip class="bg-blue-btz text-no-wrap">
                  {{ $t("tooltip.viewScript") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
  <q-dialog v-model="showDetail" ref="showDetailDialog">
    <report-refill-detail-card
      @refresh="refresh"
      :reportId="selectedReportId"
    />
  </q-dialog>
</template>
<script>
import ReportApi from "../apis/Report";
import ReportRefillDetailCard from "../components/report/ReportRefillDetailCard.vue";

import { useI18n } from "vue-i18n";
import { date } from "quasar";
import { ref } from "vue";
import moment from "moment";

export default {
  name: "ScriptsPage",

  components: { ReportRefillDetailCard },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showDetail: false,
      filter: "",
      fromDate: moment().subtract(10, "d").toISOString(),
      toDate: moment().toISOString(),
      loading: false,
      export_fields: [],
      report: [],
      visibleColumns: ref([
        "id",
        "code",
        "city",
        "name",
        "agent",
        "date",
        "num_tests",
        "t_ok_before",
        "t_ok_after",
        "defect",
        "iem_not_seen",
        "iem_failed",
        "iem_door_ko",
        "iem_door_already_open",
        "actions",
      ]),

      perPage: 50,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
    };
  },
  watch: {
    fromDate: function () {
      this.getReport();
    },
    toDate: function () {
      this.getReport();
    },
  },
  methods: {
    async getReport() {
      this.loading = true;
      try {
        const reportReponse = await ReportApi.getRefillReport(
          this.fromDate,
          this.toDate
        );

        this.report = reportReponse.data.data;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async viewDetail(reportId) {
      this.showDetail = true;
      this.selectedReportId = reportId;
    },
    async refresh() {
      this.showNew = false;
      this.showDetail = false;
      await this.getReport();
    },
    get_excel_fields() {
      return {
        Id: "id",
        PDV: {
          callback: (row) => row.store.code,
        },
        Ville: {
          callback: (row) => row.store.city,
        },
        Nom: {
          callback: (row) => row.store.name,
        },
        Mandataire: {
          callback: (row) => row.store.agent,
        },
        Date: "used_at",
        Tentatives: "num_tests",
        "Num T.OK Avant": "num_ok_before",
        "Num T.OK Après": "num_ok_after",
        "Non vue après": "num_not_seen_after",
        "Attente chargement": "num_refill_pending_after",
        Anomalies: "num_defect",
        "Diff BU13": "diff_13b",
        "Diff PR13": "diff_13p",
        "Diff VI10": "diff_viseo",
        "Diff CUB5": "diff_cubeb",
        "Diff CUP5": "diff_cubep",
        "IEM Connexion Timeout": "iem_conn_timeout",
        "IEM Connexion Failed": "iem_conn_failed",
        "IEM Déconnecté": "iem_disconnected",
        "IEM Echec": "iem_failed",
        "IEM Porte KO": "iem_door_ko",
        "IEM Porte déjà ouverte": "iem_door_already_open",
      };
    },
  },

  async mounted() {
    this.$store.state.title = "button.refill";
    await this.getReport();
    this.export_fields = this.get_excel_fields();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: this.$t("label.id"),
          align: "left",
          field: "id",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "original_id",
          label: this.$t("label.original_id"),
          align: "left",
          field: "original_code_id",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "code",
          required: true,
          label: this.$t("label.code"),
          align: "left",
          field: (row) => row.store.code,
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "city",
          required: true,
          label: this.$t("label.city"),
          field: (row) => row.store.city,
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "name",
          required: true,
          label: this.$t("label.name"),
          field: (row) => row.store.name,
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "agent",
          required: true,
          label: this.$t("label.agent"),
          field: (row) => row.store.agent,
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "date",
          required: true,
          label: this.$t("label.refill_date"),
          format: (val) => date.formatDate(new Date(val), "DD-MM-YYYY HH:mm"),
          field: "used_at",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "num_tests",
          label: this.$t("label.num_try"),
          field: "num_tests",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "t_ok_before",
          required: true,
          label: this.$t("label.t_ok_before"),
          field: "num_ok_before",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "defect",
          label: this.$t("label.defect"),
          field: "num_defect",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "t_ok_after",
          required: true,
          label: this.$t("label.t_ok_after"),
          field: "num_ok_after",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "not_seen_after",
          required: true,
          label: this.$t("label.not_seen_after"),
          field: "num_not_seen_after",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "refill_pending_after",
          required: true,
          label: this.$t("label.refill_pending_after"),
          field: "num_refill_pending_after",
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "diff_bu13",
          label: this.$t("label.diff_bu13"),
          field: (row) => -row.diff_13b,
          align: "left",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "diff_pr13",
          label: this.$t("label.diff_pr13"),
          field: (row) => -row.diff_13p,
          align: "left",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "diff_vi10",
          label: this.$t("label.diff_vi10"),
          field: (row) => -row.diff_viseo,
          align: "left",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "diff_cub5",
          label: this.$t("label.diff_cub5"),
          field: (row) => -row.diff_cubeb,
          align: "left",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "diff_cup5",
          label: this.$t("label.diff_cup5"),
          field: (row) => -row.diff_cubep,
          align: "left",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "iem_not_seen",
          label: this.$t("label.iem_not_seen"),
          field: (row) => (row.error_summary ? row.iem_not_seen : ""),
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "iem_door_ko",
          label: this.$t("label.iem_door_ko"),
          field: (row) => (row.error_summary ? row.iem_door_ko : ""),
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "iem_failed",
          label: this.$t("label.iem_failed"),
          field: (row) => (row.error_summary ? row.iem_conn_timeout = row.iem_conn_failed + row.iem_disconnected + row.iem_failed: ""),
          align: "left",
          sortable: true,
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "summary",
          label: this.$t("label.summary"),
          field: "error_summary",
          align: "left",
          headerClass: "cursor-help",
          headerStyle: "position: relative",
          headerSlot: true,
        },
        {
          name: "actions",
          required: true,
          label: this.$t("label.action"),
          style: "width:70px",
        },
      ];
      return columns;
    },
  },
};
</script>
